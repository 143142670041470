import {useEffect} from "react";
import {NAVLINK_PREFIX} from "../../shared";
import {Card, CardContent} from "@mui/joy";

export default function Associations() {

    useEffect(() => {
        document.title = NAVLINK_PREFIX + "Associations";
    }, []);

    const associations = [
        {
            title: 'Cercle de Phelma',
            image: '/files/logo-assos/cercle-phelma.jpg',
            desc: '',
            link: 'https://www.facebook.com/CerclePhelma2019'
        },
        {
            title: 'BDS Phelma',
            image: '/files/logo-assos/bds-phelma.jpg',
            desc: '',
            link: 'https://www.facebook.com/BDSPhelma2017'
        },
        {
            title: 'BDA Phelma',
            image: '/files/logo-assos/bda-phelma.jpg',
            desc: '',
            link: 'https://www.facebook.com/profile.php?id=100084688800024'
        },
        {
            title: 'Grand Cercle',
            image: '/files/logo-assos/gc.jpg',
            desc: '',
            link: 'https://www.facebook.com/gean.claude.353'
        },
        {
            title: 'Alumni Grenoble INP Phelma',
            image: '/files/logo-assos/alumni-phelma.jpg',
            desc: 'Le groupement Phelma de Grenoble INP Alumni est l\'association faisant le lien entre les élèves actuels et les anciens élèves de Phelma.',
            link: 'https://www.facebook.com/groupement.phelma.alumni/'
        },
        {
            title: 'Junior Conseil Phelma',
            image: '/files/logo-assos/jcp.jpg',
            desc: '',
            link: 'https://www.facebook.com/juniorconseil.phelma.50'
        },
        {
            title: 'Cheer Up',
            image: '/files/logo-assos/cheer-up.jpg',
            desc: '',
            link: 'https://www.facebook.com/CheerUpPhelma'
        },
        {
            title: 'Phelm\'avenir',
            image: '/files/logo-assos/phelm-avenir.jpg',
            desc: '',
            link: 'https://www.facebook.com/phelm.avenir.9'
        },
        {
            title: 'INP Pulse',
            image: '/files/logo-assos/inpulse.jpg',
            desc: '',
            link: 'https://www.facebook.com/INPulsePage'
        },
        {
            title: 'Phelma Zic',
            image: '/files/logo-assos/phelma-zic.jpg',
            desc: 'Phelma Zic, c’est l’asso musique de Phelma. On te propose de rencontrer d’autres musiciens et chanteurs, de monter ton groupe, pouvoir venir répéter dans une salle toute équipée, t\'enregistrer et même jouer aux différents concerts et scènes ouvertes. Alors viens partager ta musique, ton instrument, ta voix et de bons moments avec nous !',
            link: 'https://www.facebook.com/phelmazic'
        },
        {
            title: 'Club Mix Grenoble INP',
            image: '/files/logo-assos/clubmix-ginp.jpg',
            desc: '',
            link: 'https://www.facebook.com/clubmixgrenobleinp'
        },
        {
            title: 'Folk Dance Band Grenoble INP',
            image: '/files/logo-assos/folk-dance-ginp.jpg',
            desc: 'Tu es musicien.ne et tu aimes les musiques traditionnelles des Îles Britanniques ? Alors rejoins The INP Folk Dance Band !\nNous sommes un club de Grenoble INP qui organisons des animations dansantes où nous faisons danser les participants sur de la musique celtique, écossaise et j\'en passe.\nN\'hésite pas à nous contacter via notre page\nFacebook ou sur inp.folkdanceband@gmail.com si tu es intéressé.e ou que tu as des questions.\nEt pour les non musiciens ? Vous serez toujours les bienvenus à nos soirées pour apprendre, aux côtés de notre maître à danser, les pas des danses en groupe.',
            link: 'https://www.facebook.com/INPFDB'
        },
        {
            title: 'Le Chalet',
            image: '/files/logo-assos/chalet-phelma.jpg',
            desc: '',
            link: 'https://www.facebook.com/foyerbar.phelma'
        },
        {
            title: 'Robotronik Phelma',
            image: '/files/logo-assos/robotronik.jpg',
            desc: '',
            link: 'https://robotronik.fr'
        },
        {
            title: 'Club Astro Grenoble INP',
            image: '/files/logo-assos/club-astro-ginp.jpg',
            desc: '',
            link: 'https://www.facebook.com/ClubAstronomieINPG'
        },
        {
            title: 'La Menuisette',
            image: '/files/logo-assos/menuisette.jpg',
            desc: 'La Menuisette c’est quoi ? C\'est un peu l\'association DIY de Phelma ! \nElle est née de l\'envie de plusieurs étudiants d\'unir leur instinct démolisseur à leur fibre artistique (oui ça existe même en école d\'ingé !) en utilisant des palettes ne servant plus ou autre type de bois on va pas chipoter \nElle est constituée de deux pôles pour le moment. \nLe pôle Construction offre à nos membres la possibilité de créer n\'importe quel outil, pratique ou décoratif, à partir de palettes et avec notre aide. Ce pôle fabrique aussi, contre monnaie sonnante et trébuchante, des objets commandés par des personnes extérieures à l\'association.\nLe pôle DIY offre des ateliers ouverts à tous ! \nEn bref, la Menuisette, c\'est l\'asso qui va vous en faire voir de toutes les palettes !\nN’hésitez pas à nous suivre sur Instagram (lamenuisette_phelma) et sur Facebook\nÀ plus dans le bus les enfants !',
            link: 'https://www.facebook.com/lamenuisette.phelma.5'
        },
        {
            title: 'InProd Grenoble INP',
            image: '/files/logo-assos/inprod.jpg',
            desc: 'L’INProd est l’association étudiante de photo/vidéo de Grenoble INP.\nDes soirées, en passant par les évènements sportifs, culturels, ou tout simplement un projet personnel... les projets sont divers !\nTu veux devenir reporter et couvrir les événements, participer à des concours ou juste te faire plaisir sur les créations de ton choix ?\nTu veux apprendre et progresser en photo et vidéo, quel que soit ton niveau ?\nAlors l’INProd est faite pour toi ! N’hésite plus et rejoins-nous !',
            link: 'https://www.facebook.com/INProd'
        },
        {
            title: 'Raid Grenoble INP',
            image: '/files/logo-assos/raid.jpg',
            desc: '',
            link: 'https://www.facebook.com/grenobleinpraid'
        },
    ]

    return <div className={"flex flex-col mx-5 lg:mx-16 gap-3"}>
        <div className={"mt-6 flex flex-col"}>
            <h3 className={"text-4xl mb-3"}>Associations</h3>
        </div>
        <div className="md:columns-2 lg:columns-3">
            {
                associations
                    .map(association => {
                        return <Card variant="outlined"
                                     className="mb-3"
                                     key={association.title}>
                            <CardContent orientation="vertical" className="flex">
                                <div className="mx-auto">
                                    <img
                                        className={"w-32 h-32 mx-auto rounded-full"}
                                        src={association['image']}
                                        loading="lazy"
                                        alt=""
                                    />
                                    <div className="mt-2 text-center">
                                        {
                                            association['link'].length > 0
                                                ? <a className={"text-xl underline cursor-pointer"}
                                                     href={association['link']}>{association['title']}</a>
                                                : <a className={"text-xl"}>{association['title']}</a>
                                        }
                                        {
                                            association['desc'].length > 128
                                                ? <p>{association['desc'].slice(0, 128)}...</p>
                                                : <p>{association['desc']}</p>
                                        }
                                    </div>
                                </div>

                            </CardContent>
                        </Card>
                    })
            }
        </div>

    </div>
}
