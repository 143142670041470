import pdfDocuments from '../pdfs.json'
import {useEffect, useState} from "react";
import {Button, FormControl, FormLabel, Radio, RadioGroup} from "@mui/joy";
import {Pagination} from "@mui/material";
import NumeroFile from "../components/NumeroFile";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NAVLINK_PREFIX} from "../shared";

const FILES_PER_PAGES = 9;

export default function Numeros() {
    const categories = [...new Set(pdfDocuments.map(pdfDocument => pdfDocument.category))];

    const [value, setValue] = useState(categories[2]);
    const [page, setPage] = useState(1);

    const maxPage = () => {
        return Math.floor(fileForCurrentCat().length / FILES_PER_PAGES) + 1
    }

    const fileForCurrentCat = () => {
        return pdfDocuments.filter(pdfDocument => pdfDocument.category === value)
    }

    useEffect(() => {
        document.title = NAVLINK_PREFIX + "Numéros";
    }, []);

    return <div className="flex flex-col p-2 lg:mx-16">
        <h3 className={"text-3xl font-semibold mb-1 mt-4"}>Anciens Phelma News</h3>
        <div className="flex lg:flex-row flex-col my-3">
            <h6 className="font-semibold text-lg mb-2">
                Type de numéro
            </h6>
            <div className="m-1 ml-5">
                <RadioGroup
                    defaultValue={categories[0]}
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={(event) => {
                        setValue(event.target.value)
                        setPage(1)
                    }}
                    orientation={window.innerWidth > 992 ? "horizontal" : "vertical"}
                >
                    {categories.map(category => {
                        return <Radio color="danger" value={category} label={category} key={category}/>
                    })
                    }
                </RadioGroup>
            </div>

        </div>

        <Pagination className="mx-auto mb-3"
                    count={maxPage()}
                    page={page}
                    onChange={(e, v) => setPage(v)}/>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
            {
                pdfDocuments
                    .filter(pdfDocument => pdfDocument.category === value)
                    .sort((a, b) => b.num - a.num)
                    .slice((page - 1) * FILES_PER_PAGES, page * FILES_PER_PAGES)
                    .map(pdfDocument => {
                        return <NumeroFile numeroData={pdfDocument} key={pdfDocument}/>
                    })
            }
        </div>
        <div className="flex w-full my-3">
            <div className="mr-auto">
                <Button
                    color="danger"
                    disabled={page <= 1}
                    onClick={() => setPage(page - 1)}
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-1"/>
                    Précédent

                </Button>
            </div>
            <div className="ml-auto">
                <Button
                    color="danger"
                    disabled={page > maxPage() - 1}
                    onClick={() => setPage(page + 1)}
                >
                    Suivant
                    <FontAwesomeIcon icon={faArrowRight} className="ml-1"/>
                </Button>
            </div>
        </div>
    </div>


}
