import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import Layout from "../components/Layout";
import Home from "./Home";
import Numeros from "./Numeros";
import Interviews from "./articles/Interviews";
import CritiqueFilmDune from "./articles/CritiqueFilmDune";
import Top20de2020 from "./articles/Top20de2020";
import Associations from "./ressources/Associations";
import ListesGrenobleINP from "./ressources/ListesGrenobleINP";
import RapsDeListes from "./ressources/RapsDeListes";
import Articles from "./articles/Articles";
import Ressources from "./ressources/Ressources";
import About from "./About";

export default function App() {
    return (<BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/numeros" element={<Numeros/>}/>
                    <Route path="/articles" element={<Articles/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/articles/interviews-filliere" element={<Interviews/>}/>
                    <Route path="/articles/top-20-de-2020" element={<Top20de2020/>}/>
                    <Route path="/articles/critique-film-dune" element={<CritiqueFilmDune/>}/>
                    <Route path="/ressources" element={<Ressources/>}/>
                    <Route path="/ressources/associations" element={<Associations/>}/>
                    <Route path="/ressources/listes" element={<ListesGrenobleINP/>}/>
                    <Route path="/ressources/raps" element={<RapsDeListes/>}/>
                    <Route path="*" to='/' />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
