import {useEffect, useState} from "react";
import {NAVLINK_PREFIX} from "../../shared";
import {Card, CardContent, Select, Option, Typography, CardOverflow, AspectRatio} from "@mui/joy";
import lists from '../../listes.json';

export default function ListesGrenobleINP() {

    useEffect(() => {
        document.title = NAVLINK_PREFIX + "Listes Grenoble INP";
    }, []);

    const schoolColorMapping = {
        "Phelma": "danger",
        "Ense 3": "primary",
        "Ensimag": "success",
        "Pagora": "Warning",
    }

    const school = [...new Set(lists.map(liste => liste['ecole']))];
    const [schoolFilter, setSchoolFilter] = useState("");

    const yearGroup = [...new Set(lists.map(liste => liste['promo']))];
    const [yearGroupFilter, setYearGroupFilter] = useState("");

    const campagnes = [...new Set(lists.map(liste => liste['campagne']))];
    const [campaignFilter, setCampaignFilter] = useState("");

    const listMappingForCurrentContext = function () {
        const filteredLists = lists.filter(liste => {
            return !((schoolFilter !== "" && liste['ecole'] !== schoolFilter)
                || (yearGroupFilter !== "" && liste['promo'] !== yearGroupFilter)
                || (campaignFilter !== "" && liste['campagne'] !== campaignFilter));
        })

        let listsMapping = [];
        [...new Set(
            filteredLists.map(lists => lists['promo'])
        )].forEach(yearGroupSection => {
            listsMapping.push({
                yearGroup: yearGroupSection,
                lists: filteredLists.filter(filteredList => filteredList['promo'] === yearGroupSection)
            })
        })
        return listsMapping;
    }

    return <div className={"flex flex-col mx-5 lg:mx-16 gap-3"}>
        <div className={"mt-6 flex flex-col"}>
            <h3 className={"text-4xl"}>Listes</h3>
        </div>
        <div>
            <h6 className="font-semibold text-lg mb-2">
                Filtres
            </h6>
            <div className={"flex gap-3 lg:flex-row flex-col"}>
                <Select defaultValue=""
                        placeholder="Filtrer par école"
                        onChange={(event, newValue) => setSchoolFilter(newValue)}>
                    <Option value="">Toutes les écoles</Option>
                    {
                        school.map(ecole => {
                            return <Option
                                value={ecole}
                                key={ecole}
                            >{ecole}</Option>
                        })
                    }
                </Select>

                <Select defaultValue=""
                        placeholder="Filtrer par promo"
                        onChange={(event, newValue) => setYearGroupFilter(newValue)}>
                    <Option value="">Toutes les promos</Option>
                    {
                        yearGroup.map(promo => {
                            return <Option
                                value={promo}
                                key={promo}
                            >{promo}</Option>
                        })
                    }
                </Select>

                <Select defaultValue=""
                        placeholder="Filtrer par campagne"
                        onChange={(event, newValue) => setCampaignFilter(newValue)}>
                    <Option value="">Toutes les campagnes</Option>
                    {
                        campagnes.map(campagne => {
                            return <Option
                                value={campagne}
                                key={campagne}
                            >{campagne}</Option>
                        })
                    }
                </Select>
            </div>
        </div>

        {listMappingForCurrentContext().map(year => {
            return <div key={"year-section-" + year}>
                <h4 className={"text-lg p-1 font-semibold"}>
                    Promo {year['yearGroup']}
                </h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
                    {
                        year['lists'].map(liste =>
                            <Card orientation="horizontal"
                                  variant="outlined"
                                  key={"list-section-" + liste['nom']}>
                                <CardOverflow>
                                    <AspectRatio ratio="1" sx={{width: 110}}>
                                        <img
                                            src={liste['image']}
                                            loading="lazy"
                                            alt=""
                                            style={{
                                                'background-image': "url('/files/listes/default.jpg')",
                                                'background-position': 'center',
                                                'background-repeat': 'no-repeat'
                                            }}
                                        />
                                    </AspectRatio>
                                </CardOverflow>
                                <CardContent>
                                    <Typography fontWeight="lg" mb={0.5}>
                                        {liste['nom']}
                                    </Typography>
                                    <Typography level="body-sm">{liste['campagne']} {liste['promo']}</Typography>
                                </CardContent>
                                <CardOverflow
                                    variant="soft"
                                    color={schoolColorMapping[liste['ecole']]}
                                    sx={{
                                        px: 0.2,
                                        writingMode: 'vertical-rl',
                                        textAlign: 'center',
                                        fontSize: 'xs',
                                        fontWeight: 'xl',
                                        letterSpacing: '1px',
                                        textTransform: 'uppercase',
                                        borderLeft: '1px solid',
                                        borderColor: 'divider',
                                    }}
                                >
                                    {liste['ecole']}
                                </CardOverflow>
                            </Card>)
                    }
                </div>
            </div>
        })
        }
    </div>
}
