import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

export default function Navbar() {
    const menuEntries = [
        {
            rank: 1,
            title: 'Accueil',
            url: '/',
        },
        {
            rank: 2,
            title: 'Anciens numéros',
            url: '/numeros',
        },
        {
            rank: 3,
            title: 'Articles & Interviews',
            url: '/articles',
        },
        {
            rank: 4,
            title: 'Ressources',
            url: '/ressources',
        },
        {
            rank: 5,
            title: 'A propos',
            url: '/about',
        },
    ]
    const [currentTab, setCurrentTab] = useState(999)
    const navigate = useNavigate();

    const gotoPages = (menuEntry) => {
        setCurrentTab(menuEntry.rank)
        navigate(menuEntry.url);
    }

    const location = useLocation();

    useEffect(() => {
        const pageIndex = menuEntries.filter(menuEntry => {
            if (!location.pathname.includes('/'))
                return null;
            const moduleLocation = location.pathname.split('/')[1];
            return menuEntry.url === "/" + moduleLocation
        });
        if (pageIndex.length === 1 && pageIndex[0].rank)
            setCurrentTab(pageIndex[0].rank)
    }, [])

    return <div className="flex lg:gap-2 lg:flex-row bg-[#f1f1f1] text-black cursor-pointer lg:rounded-lg">
        {menuEntries.sort((a, b) => a.id < b.id).map((menuEntry) => {
            return <div
                className={"transition ease-in-out p-1 text-black flex-1 flex lg:text-lg hover:bg-[#323232] hover:text-white text-center lg:rounded-lg "
                    + (currentTab === menuEntry['rank'] ? 'bg-red-700 text-white' : '')}
                onClick={() => gotoPages(menuEntry)}
            key={menuEntry.rank}>
                <h6 className="m-auto">
                    {menuEntry['title']}
                </h6>
            </div>
        })}
    </div>
}
