import {useEffect} from "react";
import {NAVLINK_PREFIX} from "../../shared";

export default function RapsDeListes() {
    useEffect(() => {
        document.title = NAVLINK_PREFIX + "Raps de liste";
    }, []);

    return <div className={"flex flex-col mx-5 lg:mx-16 gap-3"}>
        <div className={"mt-6 flex flex-col"}>
            <h3 className={"text-4xl"}>Raps de liste</h3>
        </div>
        <b className={"mt-2 text-xl -mb-2.5"}>
            Spotify
        </b>
        <p>
            Le client intégré Spotify ne propose que les 30 premières secondes. Pour avoir les morceaux complets, ouvrez cette playlist en appuyant sur le logo Spotify.
        </p>
        <iframe className="border-radius:12px"
                src="https://open.spotify.com/embed/playlist/2SWMkgNIAjI37H4M3E0Xvc?utm_source=generator&theme=0" width="100%"
                height="352" frameBorder="0" allowFullScreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        <b className={"mt-2 text-xl -mb-2.5"}>
            Youtube
        </b>
        <p>
            Pour voir toutes les vidéos, ouvrir la miniature dans Youtube
        </p>
        <iframe  src="https://www.youtube.com/embed/videoseries?list=PLpx116XpEwY9GxwXvrkU1IvKILh-YS2zw"
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen></iframe>
    </div>
}
