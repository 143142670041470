import {useEffect} from "react";
import {NAVLINK_PREFIX} from "../../shared";
export default function CritiqueFilmDune() {
    useEffect(() => {
        document.title = NAVLINK_PREFIX + "Critique de Film: Dune";
    }, []);

    return <div className={"flex flex-col mx-5 lg:mx-16 gap-3"}>
        <div className={"mt-6 flex flex-col"}>
            <h3 className={"text-4xl"}>Critique de Film : Dune</h3>
            <h4 className={"text-md inline text-neutral-600 mt-3 align-bottom"}>par Nino VIVIAND</h4>
        </div>
        <p>
            Dune est une adaptation du roman de science-fiction éponyme de Frank Herbert. Réalisé par Denis Villeneuve, le film
            compte comme acteurs principaux Timothée Chalamet, Rebecca Ferguson et Zendaya. C'est la première partie d'une saga
            d'au moins deux films.
        </p>
        <b className={"mt-2 -mb-2.5"}>
            Synopsis
        </b>
        <p>
            On suit l'histoire de Paul Atréides, fils du Duc des Atréides. Alors que leur peuple est appelé à régner sur Dune,
            une planète aussi riche en ressource rare que dangereuse, le jeune Paul comprend qu'il possède une plus grande
            destinée que celle d'un simple héritier...
        </p>
        <b className={"mt-2 -mb-2.5"}>
            Critique
        </b>
        <p>
            Grandiose, c'est l'adjectif qui semble convenir le mieux à Dune. Même en tant qu'ignorant complet de l'univers créé
            par Herbert, ce film parvient à vous le faire découvrir dès les premières minutes. On est plongé dans ce désert
            magnifique et redoutable grâce à la photographie et la bande sonore. Les images ne sont pas seulement esthétiques,
            elles réussissent également à représenter la grandeur de cet univers. Les scènes de combat ne dérogent pas à la
            règle, au contraire, ce sont peut-être les plus réussies. Quant à la musique, on ne pouvait évidemment pas être déçu
            par Hans Zimmer, le compositeur-génie du cinéma. Afin de profiter aux mieux de ses principales qualités, il est
            évidemment nécessaire sinon recommandable de le voir sur grand écran!
        </p>
        <p>
            Quelques grains de sable (de la Dune !) subsistent dans la machine. Le rythme est légèrement cassé par la répétition
            de flashbacks et de ralentis. Ceux-ci ne posent aucun problème dans un film de longueur classique mais peuvent
            devenir gênants lorsque celui-ci fait plus de 2h30 ! Heureusement, le réalisateur québécois nous tient en haleine
            jusqu'à la fin de son œuvre grâce à une deuxième partie particulièrement réussie dont le rythme augmente de façon
            exponentielle. Quant à l'acteur principal, Timothée Chalamet, il semble concéder un peu de naturel pour obtenir plus
            de crédibilité dans son rôle. Mais, son jeu s'améliore au fil du long-métrage et nul doute qu'il sera encore
            meilleur dans le deuxième volet.
        </p>
        <p>
            Excellent film Premier contact et la suite moderne du chef d'œuvre de Ridley Scott Blade Runner 2049, Denis
            Villeneuve confirme son statut de grand réalisateur hollywoodien.
        </p>
    </div>
}
