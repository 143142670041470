import {useEffect} from "react";
import {NAVLINK_PREFIX} from "../../shared";

export default function Top20de2020() {
    useEffect(() => {
        document.title = NAVLINK_PREFIX + "Top 20 de 2020";
    }, []);

    return <div className={"flex flex-col mx-5 lg:mx-16 gap-3"}>
        <div className={"mt-6 flex flex-col"}>
            <h3 className={"text-4xl"}>Top 20 de 2020</h3>
            <h4 className={"text-md inline text-neutral-600 mt-3 align-bottom"}>par Nicolas TANZI</h4>
        </div>
        <p>
            Cette année 2020 fut décidément remplie de surprises et de rebondissements ! Au-delà de la découverte pour la
            population de ce qu’est un pangolin, vous voulez peut-être connaître plus précisément quelques faits marquants
            qui
            ont bouleversé (ou pas) les quelques humanoïdes qui s’agitent sur un gros caillou, lors de sa 2020ème rotation
            autour du soleil après qu’un certain hippie barbu a fait du surf sur la méditerranée ? Vous lisez alors le bon
            article, car voici le Top 20 officiel des évènements de l’année 2020 ! Évidemment, il s’agit d’un top subjectif,
            si
            vous n’êtes pas en accord avec le classement, vous pouvez mettre un pouce vers le bas et rager dans les
            commentaires. Attention, le numéro 11 risque de vous faire tomber à la renverse.
        </p>
        <b className={"mt-2 -mb-2.5"}>
            Hors classement :
        </b>
        <p>
            On ne commencera pas ce top directement avec le véritable classement, car je réserve une place « hors-classement
            » à notre collègue le microbe à couronne. Inclassable, tant les évènements de cette liste en découlent quasiment
            tous. On ne le présente plus, le Covid (ou la Covid en réalité, mais face à cette terrible erreur de choix je
            m’obstinerai à le nommer au masculin) est arrivé par surprise pour nous concocter une formidable année. Cet
            impromptu énergumène, dont l’origine fait toujours malheureusement débat est parvenu à bouleverser mondialement
            la société humaine en quelques semaines. L’humanité se retrouve alors face à un ennemi commun, s’aperçoit avec
            amertume de ses erreurs passées et commence donc à s’unir face à l’adversité tel un rempart indestructible. Non
            pas du tout. C’est encore plus n’importe quoi, comme le montrera en grande partie cette petite liste cocasse.
            Heureusement, tout ne tourne pas autour de ce virus. Rassurez-vous, les hommes ont plein d’autres idées pour
            fournir des mauvaises nouvelles…
        </p>
        <b className={"mt-2 -mb-2.5"}>
            Numéro 20 : Le pangolin fait son apparition
        </b>
        <p>
            Petit mammifère insectivore écailleux, faisant partie de la famille des manidae, le pangolin se dévoile
            mondialement
            en cette année 2020. Pourquoi subitement tant de notoriété pour cet animal méconnu ? Tout simplement parce que
            l’information indiquant qu’il serait à l’origine de la crise sanitaire a été massivement diffusée. Le problème,
            c’est que cette hypothèse n’est malheureusement pas réellement vérifiée. Il s’agit donc de l’occasion de causer
            rapidement de faune et d’écosystème. Effectivement, la thèse du pangolin n’est pas avérée notamment parce qu’il
            manque des espèces intermédiaires vecteurs du virus entre le pangolin et l’homme qui n’ont pas été identifiées
            dans
            la chaine de transmission. La chauve-souris est également une candidate pour l’origine du virus, ce qui
            contredirait
            l’hypothèse précédente. Quoi qu’il en soit, l’Homme a tendance à détourner le regard du véritable problème : le
            dérèglement de l’écosystème. L’activité humaine grandissante a tendance à détruire les habitats naturels de
            nombreuses espèces, ce qui favorise les contacts entre ces animaux et les humains, en créant de nouvelles
            chaines de
            transmission de maladies. Mais il semblerait que cela soit bien plus difficile à croire qu’un complot mondial
            organisé par les chinois…
        </p>
        <b className={"mt-2 -mb-2.5"}>
            Numéro 19 : La crise du papier toilette
        </b>
        <p>
            Indéniablement un des évènements majeurs de cette année, la rupture de stock de notre soyeux ruban hygiénique a
            généré moult inquiétudes au sein de la population ainsi que des crises d’angoisse dignes des plus grands films
            horrifiques. Cet épisode tragique aura eu le mérite de souligner une nouvelle fois l’absurde folie humaine.
            Comme
            disait Albert Einstein : « Deux choses sont infinies : l’univers et la bêtise humaine. Mais en ce qui concerne
            l’univers, je n’en ai pas encore acquis la certitude absolue. »
        </p>


        <b className={"mt-2 -mb-2.5"}>
            Numéro 18 : Astronomie
        </b>
        <p>
            Parlons un peu de l’espace pour s’éloigner de l’agitation terrestre. Un nouveau trou noir a été détecté, et il
            s’agit du trou noir connu le plus proche de la Terre. Il se situe "seulement" à 1000 années-lumière de notre
            planète. Pour plus d’informations sur les trous noirs, vous pouvez vous référer au formidable article dans le
            Phelma
            News du mois de novembre. La plus grande carte en 3D de l’univers a également été publiée, après 20 ans de
            travail
            titanesque. Le résultat est à la fois renversant et captivant. Sur un ton plus décalé, cette année des
            scientifiques
            ont identifié le premier décès par météorite, en 1888 en Irak. D’après les probabilités la victime avait une
            chance
            sur 1 600 000 de se faire percuter par un objet céleste. Il aurait mieux fait de jouer au loto, pas de chance.
        </p>

        <b className={"mt-2 -mb-2.5"}>
            Numéro 17 : Cinéma
        </b>

        <p>
            Sean Connery, un monument du cinéma est décédé cette année à l’âge de 90 ans. Bien connu pour son mythique
            premier
            rôle en tant que James Bond, il s’est illustré tout au long de sa carrière comme un immense acteur pendant près
            de
            60 ans et sur plus de 70 films. Ennio Morricone, compositeur de génie nous a également quittés. Il a sublimé les
            plus grands westerns de sa musique, et a terminé en beauté en composant pour Quentin Tarantino sur Les Huit
            Salopards. Mais quoi de mieux que le 7ème - et meilleur - art pour oublier les évènements peu joyeux qui
            pleuvent
            dans cette liste. C’est donc l’occasion de mentionner les 4 Oscars obtenus cette année pour Parasite, réalisé
            par
            Bong Joon-Ho (même s’il est sorti l’an dernier). Un film surprenant, engagé et mêlant une multitude de genres. À
            voir absolument.
        </p>

        <b className={"mt-2 -mb-2.5"}>
            Numéro 16 : Crash d’un avion en Iran
        </b>
        <p>
            Bien, maintenant que l’on s’est bien détendu avec un bon film, passons à la triade des mauvaises nouvelles. Tout
            d’abord, en janvier, un avion quittant l’aéroport de Téhéran en Iran pour se rendre au Canada s’écrase quelques
            minutes après le décollage. Le gouvernement Iranien parle d’un accident, l’avion aurait pris feu. L’enquête
            avance
            et fait part de missiles, le gouvernement avoue alors avoir envoyé des missiles "par erreur". Cependant, les
            tensions naissantes entre l’Iran et les États-Unis laissent à penser que la raison est tout autre et le sujet ne
            manque pas de faire polémique.
        </p>


        <b className={"mt-2 -mb-2.5"}>
            Numéro 15 : Explosions à Beyrouth
        </b>

        <p>
            En août dernier, Le Liban connait la terreur lors de deux gigantesques explosions à Beyrouth. La détonation a
            été
            produite à partir de la combustion du stock colossal d’un certain produit chimique : le nitrate d’ammonium. Les
            2750
            tonnes de ce produit, stockées dans le port de la capitale depuis 2013, étaient censées être conservées de
            manière
            très stricte eut égard à la haute inflammabilité du composé. Les explosions qui résultent de la combustion, aux
            allures de champignon nucléaire, ont rasé de nombreux bâtiments et propagé leur souffle sur plusieurs dizaines
            de
            kilomètres à la ronde, causant 171 morts et 6 500 blessés. Des manifestations ont alors éclaté pour dénigrer
            l’incompétence du gouvernement.
        </p>

        <b className={"mt-2 -mb-2.5"}>
            Numéro 14 : Assassinat par drone
        </b>
        <p>
            En janvier, le général Iranien Qassem Soleimani se fait assassiner par les États-Unis. Notons que l’attaque a
            été
            effectuée à l’aide de drones, décidément les nouvelles technologies trouvent toutes sortes d’utilisation selon
            les
            mains dans lesquelles elles tombent. Cet assassinat n’a pas manqué de faire monter encore plus les tensions déjà
            présentes entre Iran et États-Unis, d’autant plus que Soleimani qui est mort en martyre était un personnage
            politique important dans son pays, commandant de l’unité d’élite des Gardiens de la Révolution depuis 22 ans.
        </p>


        <b className={"mt-2 -mb-2.5"}>
            Numéro 13 : Le phénomène frappe une 13ème fois
        </b>
        <p>
            Une nouvelle plus rassurante cette fois, elle concerne le tennis. Qui dit numéro 13 du top, dit 13ème victoire
            de
            Rafael Nadal lors du tournoi annuel de Roland-Garros. Inarrêtable sur terre battue, le majorquin continue
            d’exploser
            tous les records du tournoi avec 100 victoires pour 2 défaites à Roland-Garros. Il égale aussi le record de 20
            titres en Grand Chelem détenu par Roger Federer.
        </p>


        <b className={"mt-2 -mb-2.5"}>
            Numéro 12 : Shinzou wo Sasageyo !!!
        </b>
        <p>
            Il est temps de parler d’une œuvre colossale : L’attaque des titans. Ce manga écrit et dessiné par Hajime
            Isayama
            depuis 2009 a connu une popularité grandissante notamment grâce à sa narration de génie ainsi qu’à l’adaptation
            animée, qui se poursuit en décembre avec la 4ème et ultime saison. Je ne ferai pas l’affront de spoiler quoique
            ce
            soit ici, mais étant données l’époustouflante maturité de l’œuvre et ses thématiques plus que déroutantes et
            pourtant si proches de notre réalité, je me permets d’estimer que cette saison marquera un tournant dans
            l’histoire
            de l’animation japonaise, tout comme cette histoire restera certainement culte pendant des générations.
        </p>


        <b className={"mt-2 -mb-2.5"}>
            Numéro 11 : La légende vivante
        </b>
        <p>
            On se rapproche dangereusement du top 10 et voici venir un évènement capital, qui en aura chamboulé plus d’un et
            marqué une rupture entre le passé et l’avenir. Je veux bien évidemment parler de l’obtention d’une fabuleuse
            carte
            noire du Chalet par Patrice Petitclair à la tombola du BDS, lui conférant ainsi la fabuleuse possibilité de ne
            pas
            aller boire de café puisque tout est fermé.
        </p>


        <b className={"mt-2 -mb-2.5"}>
            Numéro 10 : Le miracle
        </b>
        <p>
            On y est, on entre dans les 10 évènements les plus marquants de l’année 2020. Et on commence en beauté avec le
            miracle de l’année, l’annulation des TP, des oraux et du TIPE pour les élèves des classes préparatoires aux
            grandes
            écoles. Cette bénédiction divine aura su raviver la flemme (euh la flamme) de bon nombre de taupins, leur
            évitant un
            tour de France en plein été pour venir bégayer sur une démonstration du théorème du rang ou sur une explication
            foireuse du Maxpid.
        </p>


        <b className={"mt-2 -mb-2.5"}>
            Numéro 9 : Le Brexit se concrétise enfin
        </b>
        <p>
            Ce 31 janvier 2020, après 47 ans passés dans l’union européenne, et 1317 jours après le referendum de 2016, le
            Royaume-Uni a enfin officiellement concrétisé le fameux Brexit. Une nouvelle qui passe presque inaperçue avec la
            crise sanitaire qui a suivi, mais qui aura certainement de nombreuses conséquences à long terme pour les
            britanniques. Une période de transition jusqu’à la fin de l’année 2020 est tout de même prévue.
        </p>


        <b className={"mt-2 -mb-2.5"}>
            Numéro 8 : Le pastaga et la chloroquine
        </b>
        <p>
            En 2020 on se souviendra évidemment du professeur marseillais Didier Raoult et son hydroxychloroquine. Le site
            The
            Skeptic lui a décerné le prix annuel du rasoir rouillé, habituellement réservé aux pires scientifiques de
            l’année
            qui ont promu les pseudosciences. L’efficacité du "médicament miracle" fait toujours débat chez certains,
            néanmoins
            cet évènement fait réfléchir sur le rôle des scientifiques dans la propagation des informations, et sur le
            manque de
            recul de la population. Les grands scientifiques reconnus bénéficient de ce que l’on appelle communément la
            "parole
            d’autorité", permettant de prendre pour argent comptant tout ce qu’ils peuvent énoncer. Il est normal de faire
            confiance à des spécialistes, mais il est capital de savoir prendre du recul sur chaque information et de les
            vérifier. Malheureusement, pour le cas du professeur Raoult, trop peu de rigueur a été accordée à ses
            expériences,
            et l’excès d’impatience de la population face à la crise sanitaire a propulsé un faux médicament au rang de
            produit
            miracle. C’est bien dommage, un marseillais ne sauvera pas le monde cette année…
        </p>


        <b className={"mt-2 -mb-2.5"}>
            Numéro 7 : Nos forêts brûlent
        </b>
        <p>
            Une fois n’est pas coutume, il faut parler d’environnement. L’Amazonie est en danger, c’est indéniable. Pourtant
            le
            président brésilien Jair Bolsonaro continue encore et toujours de le nier. Les feux de forêts en Amazonie ont
            battu
            des records cette année, la situation est plus qu’inquiétante pour le poumon vert de notre planète. Ces feux
            volontaires, issus de la déforestation dans le but d’obtenir des terres cultivables, se sont comptés en dizaine
            de
            milliers durant les 10 premiers jours d’août.
        </p>


        <b className={"mt-2 -mb-2.5"}>
            Numéro 6 : Black Lives Matter
        </b>
        <p>
            Cette année, le mouvement Black Lives Matter est plus que jamais au cœur du sujet aux États-Unis. Il vise à
            militer
            contre la discrimination de la communauté afro-américaine, et il a connu un regain d’intérêt cette année
            notamment à
            cause de violences policières sur des individus noirs. La mort de George Floyd est donc celle qui a certainement
            le
            plus marqué cette année, l’homme a été immobilisé avec force suite à une interpellation, et est décédé
            d’asphyxie.
            Malgré toutes les avancées sociales pour la communauté noire, les tensions sont toujours irrémédiablement
            présentes,
            à croire que les mentalités n’évoluent jamais.
        </p>


        <b className={"mt-2 -mb-2.5"}>
            Numéro 5 : Un génocide en arrière-plan
        </b>
        <p>
            Pendant que l’Homme se démène avec ses problèmes quotidiens, les Ouïghours se font persécuter dans des "camps de
            rééducation" depuis plusieurs années par le gouvernement chinois. La situation dramatique de cette ethnie n’a
            fait
            surface que récemment et commence à être prise au sérieux, mais les répressions durent déjà depuis 2014.
            Qualifier
            cet acte de génocide n’est pas exagéré, tant cette minorité de la population chinoise est déshumanisée. Les
            droits
            humains fondamentaux sont donc bafoués, une situation affligeante pour une société censée être civilisée.
        </p>


        <b className={"mt-2 -mb-2.5"}>
            Numéro 4 : La liberté d’expression en danger
        </b>
        <p>
            L’assassinat de Samuel Paty en cette fin d’année a fortement choqué. Il est inadmissible, et met en exergue un
            grave
            problème d’insécurité lié à ce qui fait l’un de nos droits les plus fondamentaux en démocratie : la liberté
            d’expression. C’était d’ailleurs dans un cours lié à cette notion capitale qu’il a montré des caricatures du
            prophète issues de Charlie Hebdo. Il a été victime d’un extrémisme religieux qui entre en totale contradiction
            avec
            un autre principe fondamental : la laïcité. Le problème semble insolvable puisqu’on se rend compte que désormais
            n’importe qui peut être identifié et traqué par extrémistes.
        </p>


        <b className={"mt-2 -mb-2.5"}>
            Numéro 3 : Rester chez soi et tourner en rond
        </b>
        <p>
            Voici le podium de ce classement des plus incroyables. Vous l’attendiez tous, ou plutôt vous les attendiez tous
            car
            ce n’est pas un mais deux confinements qui nous ont été offerts cette année ! Sans conteste parmi les évènements
            les
            plus marquants de l’année, et au niveau mondial qui plus est. La solitude et la perte de motivation ont vite
            fait de
            s’installer, le tout mêlé à l’absence d’activité physique, on obtient alors les ingrédients parfaits de la
            décadence. Mention spéciale au désormais légendaire sous-titreur des discours d’Emmanuel Macron, qui doit
            certainement posséder des doigts en titane. Il nous aura bien fait marrer pendant ce confinement avec ses
            quelques
            gaffes : « La banque centrale a déjà aujourd’hui fait part joe sces quiéyeière ér ér ér ». Pour votre culture
            générale la méthode utilisée est la vélotypie, elle consiste en une écriture syllabe par syllabe et nécessite un
            clavier assez étrange.
        </p>


        <b className={"mt-2 -mb-2.5"}>
            Numéro 2 : Le duel américain
        </b>
        <p>
            Cette fin d’année, les États-Unis ont retenu l’attention générale, l’élection présidentielle a eu lieu dans un
            contexte tendu de crise sanitaire, et le monde a retenu son souffle pour savoir qui du démocrate Joe Biden ou du
            Républicain Donald Trump allait finir à la maison blanche. Le duel acharné entre nos deux septuagénaires de
            compétition, aux allures de match de football dont les supporters déchainés n’étaient autre que le peuple
            américain,
            s’est conclu par la victoire de justesse de Joe Biden. Une victoire vivement contestée par un Donald Trump
            révolté
            de par sa défaite. Il contestera notamment les votes par correspondance majoritairement démocrates, qu’il juge
            falsifiés. Difficile d’admettre la défaite après une vie entière à gagner en marchant sur les autres…
        </p>


        <b className={"mt-2 -mb-2.5"}>
            Numéro 1 : La naissance des sectes
        </b>
        <p>
            Voilà le numéro 1, la crème de la crème de cette merveilleuse année 2020, celui que j’ai élu évènement le plus
            représentatif de l’année, tant il s’y incorpore tout du long et de manière exponentielle. Au-delà des "sectes"
            qui
            se forment de plus en plus facilement via les réseaux sociaux les plus utilisés comme Youtube ou Facebook, c’est
            le
            scepticisme qui prime désormais. Pas un scepticisme visant à prendre du recul sur l’immonde masse d’informations
            qui
            nous assaille de tous les côtés, à vérifier et à croiser les sources, à s’interroger sur ce que l’on considérait
            comme acquis de manière rationnelle, non non non pas du tout. Ce scepticisme dégouline de fake news et de
            mauvaise
            foi, et nous impose sa doctrine sans questionnement. La confiance vis-à-vis du gouvernement qui se délite de
            plus en
            plus avec la gestion hasardeuse de la crise sanitaire mène la population à se réfugier vers de nouveaux moyens
            d’informations, sans nécessairement prendre conscience que ce qu’ils rejettent dans les médias "mainstream" peut
            se
            retrouver au centuple dans les sources d’information périphériques. Cette tendance risque de diviser encore plus
            les
            opinions, ce qui constitue un risque non négligeable face à une crise sanitaire sans précédent qui serait bien
            mieux
            gérée avec un minimum de cohésion. Le récent documentaire Hold-up vient évidemment mettre de l’huile sur le feu
            à
            cette marmite déjà bouillante. Et comme il est dérisoire de vouloir tenter de démonter des théories du complot
            sans
            les alimenter, je vous renvoie à l’excellente vidéo de Clément Viktorovitch sur Hold-up qui énonce très
            clairement
            et de manière concise ce qu’il y a à retenir. En bref, les gens ont désormais la lubie de croire qu’on veut les
            réduire en esclavage via des nanoparticules injectées grâce aux vaccins du coronavirus, et que toute cette crise
            sanitaire est un coup monté par Bill Gates pour parvenir à asservir l’humanité avec la 5G. Affaire à suivre..
        </p>
    </div>
}
