import {useEffect} from "react";
import {NAVLINK_PREFIX} from "../../shared";
import {Card, CardContent} from "@mui/joy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMusic, faPersonCane, faPersonHiking} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

export default function Ressources() {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = NAVLINK_PREFIX + "Ressources";
    }, []);

    return <div className={"flex flex-col mx-5 lg:mx-16 gap-3"}>
        <h3 className={"text-2xl mb-2 mt-4"}>Ressources</h3>
        <Card variant="outlined"
              className="cursor-pointer"
              onClick={() => navigate("/ressources/associations")}>
            <CardContent orientation="vertical"
                         className={"text-justify"}>
                <h2 className={"text-2xl"}>
                    <FontAwesomeIcon icon={faPersonHiking} className="mr-3"/>
                    Associations
                </h2>
                <h4 className={"text-lg mt-2"}>
                    Une petite présentation exhaustive des associations de l'école et de Grenoble INP.
                    N'hésitez pas à nous contacter pour figurer dans cette liste !
                </h4>
            </CardContent>
        </Card>
        <Card variant="outlined"
              className="cursor-pointer"
              onClick={() => navigate("/ressources/listes")}>
            <CardContent orientation="vertical"
                         className={"text-justify"}>
                <h2 className={"text-2xl"}>
                    <FontAwesomeIcon icon={faPersonCane} className="mr-3"/>
                    Les anciennes listes
                </h2>
                <h4 className={"text-lg mt-2"}>
                    Un rapide retour sur les anciennes listes de l'école.
                </h4>
            </CardContent>
        </Card>
        <Card variant="outlined"
              className="cursor-pointer"
              onClick={() => navigate("/ressources/raps")}>
            <CardContent orientation="vertical"
                         className={"text-justify"}>
                <h2 className={"text-2xl"}>
                    <FontAwesomeIcon icon={faMusic} className="mr-3"/>
                    Les anciens raps
                </h2>
                <h4 className={"text-lg mt-2"}>Un rapide retour sur les anciens raps de listes de l'école.</h4>
            </CardContent>
        </Card>
    </div>


}
