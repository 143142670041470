import Header from "./Header";
import Navbar from "./Navbar";
import React from "react";
import { Outlet } from 'react-router-dom';

export default function Layout() {
  return (
      <div className="bg-[#ebebeb] min-h-screen flex flex-col">
            <Header/>
            <div className="lg:max-w-5xl lg:p-5 mt-5 mx-auto w-full ">
                <div className="w-full bg-white lg:p-4 lg:rounded-lg">
                    <Navbar className="w-full"/>
                    <Outlet/>
                </div>
            </div>
      </div>
  );
}

