import {useEffect} from "react";
import {NAVLINK_PREFIX} from "../../shared";
import {Card, CardContent} from "@mui/joy";
import {useNavigate} from "react-router-dom";
import {faMicrophoneLines} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const YoutubeEmbed = ({embedId}) => (
    <div className="video-responsive">
        <iframe
            width="340"
            height="200"
            src={`https://www.youtube.com/embed/${embedId}`}
            allow="autoplay; clipboard-write; encrypted-media;picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </div>
);

export default function Interviews() {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = NAVLINK_PREFIX + "Interviews fillières";
    }, []);

    const interviews = [
        {
            stream: 'AMIS',
            embedId: 'pGW3DtEXKDg',
        },
        {
            stream: 'Biomed',
            embedId: 'UhU1i67pTEo',
        },
        {
            stream: 'EPEE',
            embedId: 'XRhHolbHkMU',
        },
        {
            stream: 'GEN',
            embedId: '0v2lCSC3GKc',
        },
        {
            stream: 'IPhy',
            embedId: 'iYBhBe3VrJM',
        },
        {
            stream: 'Nanotech',
            embedId: 'JS-Ey1_VJtc',
        },
        {
            stream: 'SEI',
            embedId: 'qFqwUjH_-68',
        },
        {
            stream: 'SEOC',
            embedId: 'QwbTTc8kFIg',
        },
        {
            stream: 'SICOM',
            embedId: 'sJqufclWeYM',
        },
        {
            stream: 'SIM',
            embedId: '-t1Rhi-D1Io',
        },
    ]

    return <div className={"flex flex-col mx-5 lg:mx-16 gap-3"}>
        <h3 className={"text-2xl mt-6"}>Interviews fillières</h3>
        <h4 className={"my-4"}>Ces interviews réalisées par le Phelma News ne sont que des recueils de témoignages
            d’actuels 2A et 3A et ne font
            pas office de présentation officielle. Elles permettent simplement d’avoir le point de vue de certains
            étudiants.
            Nous percevons notre travail comme complémentaire de celui des amphis de présentations et du site officiel
            de Phelma
            et en aucun cas ces vidéos ne doivent être prises comme substitution des présentations officielles que vous
            retrouverez en suivant ce lien : <a
                className={"underline"}
                href={"https://phelma.grenoble-inp.fr/fr/formation/cursus-ingenieur"}>
                https://phelma.grenoble-inp.fr/fr/formation/cursus-ingenieur
            </a>
        </h4>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            {
                interviews.map(interview => {
                    return <Card variant="outlined"
                                 className="cursor-pointer"
                                 onClick={() => navigate("/articles/interviews-filliere")}
                                 key={interview['stream']}>
                        <CardContent orientation="vertical"
                                     className={"text-justify"}>
                            <h2 className={"text-2xl"}>
                                <FontAwesomeIcon icon={faMicrophoneLines} className="mr-3"/>
                                {interview['stream']}
                            </h2>
                            <div className={"my-2 mx-auto"}>
                                <YoutubeEmbed embedId={interview['embedId']}/>
                            </div>

                        </CardContent>
                    </Card>
                })
            }
        </div>


    </div>


}
