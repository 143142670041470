import phelmanews_logo from '../images/phelma_new_logo.webp'

export default function Header() {


    return <div className="h-14 bg-white w-full flex flex-row shadow-md">
        <div className="flex flex-row mr-auto">
            <img src={phelmanews_logo} className="my-1 ml-3"/>
            <div className="text-3xl my-auto m-3 mt-3.5 lg:ml-6 font-['Kalam'] font-medium">
                PHELMA News
            </div>
        </div>
    </div>
}
