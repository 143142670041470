import {useEffect} from "react";
import {NAVLINK_PREFIX} from '../shared';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";
import phelmanews_logo from "../images/phelma_new_logo.webp";


export default function About() {
    useEffect(() => {
        document.title = NAVLINK_PREFIX + "A propos";
    }, []);


    return <div className={"flex flex-col mx-5 lg:mx-16 text-center text-lg"}>

        <div>
            <img src={phelmanews_logo} className="m-auto h-48 my-8"/>
            <p>
                Nous sommes, comme tu as d’ores et déjà pu le constater, le journal officiel étudiant de l’école
                Grenoble
                INP Phelma, et accessoirement une association décontractée et conviviale qui t’accueillera à bras
                ouverts !
            </p>
            <p>
                Nous proposons donc chaque mois un numéro de ce type, dans lequel tu pourras laisser libre cours à ta
                créativité débordante qui ne demande qu’à être dévoilée : des articles engagés, de la poésie, de la
                philosophie, des articles scientifiques ou des découvertes culturelles, tu auras la possibilité de
                mettre à
                profit tes talents littéraires comme bon te semble dans ce journal, mais ce n’est pas tout ! On te
                laisse
                découvrir notre travail au travers des anciens numéros.
                Si tu souhaites faire partie de l’aventure et placer ta pierre dans l’édifice, n’hésite pas à nous
                contacter
                via nos réseaux sociaux ci-dessous.
            </p>


        </div>
        <div className={"my-2"}>
            <p>
                <FontAwesomeIcon icon={faFacebook}
                                 className="mr-3"/>
                Page Facebook
            </p>
            <a href={"https://www.facebook.com/journal.phelma"}
               className={"text-blue-500 underline"}>https://www.facebook.com/journal.phelma
            </a>
        </div>
        <div>
            <p>
                <FontAwesomeIcon icon={faInstagram}
                                 className="mr-3"/>
                Compte Instagram
            </p>
            <a href={"https://www.instagram.com/phelmanews/"}
               className={"text-blue-500 underline"}>https://www.instagram.com/phelmanews/
            </a>
        </div>


    </div>

}
