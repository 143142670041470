import {useEffect} from "react";
import {NAVLINK_PREFIX} from "../../shared";
import {Card, CardContent} from "@mui/joy";
import {useNavigate} from "react-router-dom";
import {faMicrophoneLines, faRankingStar, faFilm} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Articles() {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = NAVLINK_PREFIX + "Articles";
    }, []);

    return <div className={"flex flex-col mx-5 lg:mx-16 gap-3"}>
        <h3 className={"text-2xl mb-2 mt-4"}>Articles complémentaires</h3>
        <Card variant="outlined"
              className="cursor-pointer"
              onClick={() => navigate("/articles/interviews-filliere")}>
            <CardContent orientation="vertical"
                         className={"text-justify"}>
                <h2 className={"text-2xl"}>
                    <FontAwesomeIcon icon={faMicrophoneLines} className="mr-3"/>
                    Interviews filières
                </h2>
                <h4 className={"text-lg mt-2"}>En parallèle du numéro de Décembre, le Phelma News vous propose des
                    interviews de 2A et de 3A dans les différentes filières de l'école pour vous aider à vous orienter dans
                    votre choix !</h4>
            </CardContent>
        </Card>
        <Card variant="outlined"
              className="cursor-pointer"
              onClick={() => navigate("/articles/top-20-de-2020")}>
            <CardContent orientation="vertical"
                         className={"text-justify"}>
                <h2 className={"text-2xl"}>
                    <FontAwesomeIcon icon={faRankingStar} className="mr-3"/>
                    Le top 20 de l'année 2020
                </h2>
                <h4 className={"text-lg mt-2"}>En complément du top 10 du numéro de Décembre, voici le top 20 de l'année
                    2020 : une version plus développée et
                    plus croustillante pour les plus motivés d'entre vous !</h4>
            </CardContent>
        </Card>
        <Card variant="outlined"
              className="cursor-pointer"
              onClick={() => navigate("/articles/critique-film-dune")}>
            <CardContent orientation="vertical"
                         className={"text-justify"}>
                <h2 className={"text-2xl"}>
                    <FontAwesomeIcon icon={faFilm} className="mr-3"/>
                    Critique de film - Dune
                </h2>
                <h4 className={"text-lg mt-2"}>Dune, la nouvelle adaptation par Denis Villeneuve du roman culte de Franck Herbert sortie en salles en Septembre
                    2021 vous est présentée par Nino Viviand, nouvelle recrue du Phelma News ! Une analyse complémentaire de celle
                    de Quentin Estiez dont la critique est disponible dans le numéro 63 d'octobre 2021.</h4>
            </CardContent>
        </Card>
    </div>


}
