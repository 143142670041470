import {AspectRatio, Card, CardContent, Typography} from "@mui/joy";

export default function NumeroFile({numeroData}) {
    const date = new Date(numeroData['date'])

    return (<Card variant="outlined"
                  className="cursor-pointer">
        <CardContent orientation="vertical" className="flex">
            <div className="mx-auto">
                <a href={numeroData['pdf']}>
                    <img
                        src={numeroData['pic']}
                        loading="lazy"
                        alt=""
                    />
                </a>
                <div className="mt-2">
                    <Typography level="body3">{(date.getMonth() + 1).toString().padStart(2, '0')}/{date.getFullYear()}</Typography>
                    <h4>{numeroData['num']} - {numeroData['name']}</h4>
                </div>
            </div>

        </CardContent>
    </Card>)
}
